import { computed, watch, reactive } from "vue";
import { getters, STORE_NAMESPACES } from "@/store";

const { APP } = STORE_NAMESPACES;

// Function que exporta las propiedades de un objeto y las hace reactivas
export default function useObjAsReactiveProps(dataSource) {
  const state = reactive({
    objProps: dataSource,
    disableActionBtn: getters[APP].getDisableActionBtn,
    save,
  });
  
  function save(callback) {
    // state.disableActionBtn = true;
    callback();
  }

  return state;
}

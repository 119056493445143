<template>  
    <v-container>      
    <!-- Para que un ref tome efecto el nodo debe existir en el DOM (tambien se toma si esta oculto), 
    en este caso los componentes dentro de Dialog no existen hasta que la accion userData.dialog 
    es true -->
    <v-form ref="form" v-model="validFormUser">
      <v-card elevation="0" class="d-flex mb-2 justify-center align-center" style="border-radius: 24px 24px 8px 8px;;">
        <v-card-title 
          class="pa-4 px-6 grey--text text--darken-3 font-weight-bold text-uppercase" 
          :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
        >
          Detalles de la cuenta
        </v-card-title>

        <v-spacer />
        <Button 
          text 
          color="primary"
          class="mr-4"
          :disabled="!validFormUser || userData.disableActionBtn" 
          :loading="loading"
          @click="userSave"
        >
          Guardar
        </Button>
      </v-card>

      <v-row>
        <v-col cols="12" sm="7" md="7" lg="8">
          <v-card class="pa-6 pt-0" elevation="1">
            <!-- <v-row> -->
            
            <!-- <v-card-actions class="px-3 py-0"> -->
              <!-- <div class="pt-0"> -->
                <v-card-title class="v-card__subtitle headline ml-n4" style="font-size: 18px !important;">
                  Información personal
                </v-card-title>
                <!-- <v-card-title class="headline px-1">Detalles de la cuenta</v-card-title> -->
                <!-- <div class="v-card__subtitle px-1 pt-0 pb-2">Información personal</div> -->
              <!-- </div> -->
    
              <!-- <v-spacer />
              <Button 
                text 
                color="primary"
                :disabled="!validFormUser || userData.disableActionBtn" 
                :loading="loading"
                @click="userSave"
              >
                Guardar
              </Button> -->
            <!-- </v-card-actions> -->
            <v-divider class="pb-2"></v-divider>
          <!-- </v-row> -->
    
          <v-row class="v-list-item">
            <v-col cols="12" sm="6" md="6"> 
              <VTextField
                v-model="userData.objProps.nombre"
                :rules="[FormValidations.required]"
                label="Nombre"
              ></VTextField>
            </v-col>
    
            <v-col cols="12" sm="6" md="6"> 
              <VTextField
                v-model="userData.objProps.apellidos"
                :rules="[FormValidations.required]"
                label="Apellidos"
              ></VTextField>
            </v-col>
    
            <v-col cols="12" sm="6" md="6"> 
              <VTextField
                v-model="userData.objProps.tel"
                label="Teléfono"
              ></VTextField>
            </v-col>
    
            <v-col cols="12" sm="6" md="6"> 
              <VTextField
                v-model="userData.objProps.domicilio"
                label="Domicilio"
              ></VTextField>
            </v-col>
    
            <v-col cols="12" sm="6" md="6"> 
              <VTextField
                v-model="userData.objProps.colonia"
                label="Colonia"
              ></VTextField>
            </v-col>
    
            <v-col cols="12" sm="6" md="6"> 
              <VTextField
                v-model="userData.objProps.ciudad"
                label="Ciudad"
              ></VTextField>
            </v-col>
    
            <v-col cols="12" sm="6" md="6"> 
              <VTextField
                v-model="userData.objProps.localidad"
                label="Localidad"
              ></VTextField>
            </v-col>
    
            <v-col cols="12" sm="6" md="6"> 
              <VTextField
                v-model="userData.objProps.cp"
                label="C.P."
              ></VTextField>
            </v-col>
    
            <v-col cols="12" sm="6" md="6"> 
              <VTextField
                v-model="userData.objProps.pais"
                label="Pais"
              ></VTextField>
            </v-col>
    
            <v-col cols="12" sm="6" md="6"> 
              <VTextField
                v-model="userData.objProps.estado"
                label="Estado"
              ></VTextField>
            </v-col>
          </v-row>
        </v-card>
        </v-col>
  
      <v-col cols="12" sm="5" md="5" lg="4">
        <v-card class="pa-6 pt-0" elevation="1">
          <v-card-title class="v-card__subtitle headline ml-n4" style="font-size: 18px !important;">
            Datos de inicio de sesión
          </v-card-title>
          <!-- <v-card-title class="headline">Seguridad</v-card-title> -->
          <!-- <div class="v-card__subtitle pb-2">Datos de inicio de sesión</div> -->
          <v-divider class="pb-2"></v-divider>
    
          <v-row class="v-list-item pb-3">
            <v-col cols="12" sm="12" md="12"> 
              <VTextField
                v-model="userData.objProps.email"
                :rules="[FormValidations.required, FormValidations.email]"
                label="Correo electrónico"
                hint="Utilizado para ingresar al sistema"
                validate-on-blur
                persistent-hint
              ></VTextField>
            </v-col>
    
            <v-col cols="12" sm="12" md="12"> 
              <VTextField
                v-model="contrasena"
                :label="`Establecer nueva contraseña (opcional)`"
                :placeholder="`Contraseña (opcional)`"
                :hint="`Utilizada para ingresar al sistema`"
                :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showpwd ? 'text' : 'password'"
                @click:append="showpwd = !showpwd"
                persistent-hint
              ></VTextField>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>

  </v-form>
  </v-container>
</template>

<script>
import Button from "@/components/ui/Button";
import useObjAsReactiveProps from "@/composables/useObjAsReactiveProps";
import { logout } from "@/utils/Functions";
import { APP_DEBUG_MODE as APP_DEBUG } from "@/utils/Constants";
import { getCurrentInstance, ref, onUpdated } from "vue";
import FormValidations from "@/utils/FormValidations";
import { state, actions, getters, STORE_NAMESPACES } from "@/store";

export default {
  components: {
    Button,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, USERS, DEV_ADMIN } = STORE_NAMESPACES;

    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    // actions[USERS].FETCH_ALL();
    const dataSource = getters[USERS].findById(state[APP].idUser);
    const userData = useObjAsReactiveProps(dataSource);

    const loading = getters[APP].getLoading;
    const validFormUser = ref(true);
    const contrasena = ref('');
    const showpwd = ref(false);

    onUpdated(() => {
      vm.proxy.$refs.form.validate();
    });

    function userSave() {
      const cuentaUsuarioData = {...userData.objProps};

      userData.save(() => {
        delete cuentaUsuarioData.contrasena;
        delete cuentaUsuarioData.fecha_reg;
        let contrasena_changed = false;

        if (contrasena.value.trim()) {
          cuentaUsuarioData.contrasena = contrasena.value;
          contrasena_changed = true;
        }

        cuentaUsuarioData.estatus = false;
        APP_DEBUG_MODE && console.log("Datos cuenta_usuario a enviar: ", cuentaUsuarioData);
        actions[USERS].UPDATE(cuentaUsuarioData, () => {
          setTimeout(() => {            
            if (contrasena_changed) {
              logout();
            }
          }, 300);
        });
      });
    }

    return {
      userSave,

      loading,
      contrasena,
      userData,
      validFormUser,
      showpwd,

    // Funcs. de validacion
      FormValidations,
    };
  }
};
</script>

